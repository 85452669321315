import { generatePath, LoaderFunction, redirect } from "react-router-dom";

import { setServiceSetting } from "@springtree/eva-sdk-core-service";
import { helpers } from "@springtree/eva-sdk-react-recoil";
import { EIntentAction, EvaIntentParser } from "@springtree/eva-suite-uri-parser";

import routeDefinitions from "routes/route-definitions";

const { storage } = helpers;

export const evaIntentHandlerLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);

  const parser = new EvaIntentParser();

  try {
    const intent = parser.parseUrl(url.href);

    const { action, payload, token } = intent.data;

    let redirectUrl: string;
    let requestedOrganizationUnitId: number | undefined = undefined;
    switch (action) {
      case EIntentAction.listSettings:
        redirectUrl = routeDefinitions.controlRoom.settings.overview.path;
        break;
      case EIntentAction.listUserRequirementSets:
      case EIntentAction.listUserRequirements:
        redirectUrl = routeDefinitions.people.userRequirementSets.overview.path;
        break;
      case EIntentAction.viewUserRequirementSet:
      case EIntentAction.viewUserRequirements:
        redirectUrl = generatePath(routeDefinitions.people.userRequirementSets.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createUserRequirementSet:
        redirectUrl = generatePath(routeDefinitions.people.userRequirementSets.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.listEmployees:
        redirectUrl = routeDefinitions.people.employees.overview.path;
        break;
      case EIntentAction.searchEmployee: {
        const searchParams = new URLSearchParams();
        searchParams.append("query", payload?.query ?? "");
        redirectUrl = `${routeDefinitions.people.employees.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.viewEmployee:
        redirectUrl = generatePath(routeDefinitions.people.employees.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createEmployee: {
        const createParams = new URLSearchParams();
        createParams.append("email", payload?.email ?? "");
        createParams.append("firstName", payload?.firstName ?? "");
        createParams.append("lastName", payload?.lastName ?? "");
        createParams.append("organizationUnitID", payload?.organizationUnitID ?? "");
        redirectUrl = `${generatePath(routeDefinitions.people.employees.details.path, {
          id: "new",
        })}?${createParams}`;
        break;
      }
      case EIntentAction.listRoles:
        redirectUrl = routeDefinitions.people.rolesRights.overview.path;
        break;
      case EIntentAction.searchRole: {
        const searchParams = new URLSearchParams();
        searchParams.append("query", payload?.query ?? "");
        redirectUrl = `${routeDefinitions.people.rolesRights.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.viewRole:
        redirectUrl = generatePath(routeDefinitions.people.rolesRights.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createRole: {
        redirectUrl = generatePath(routeDefinitions.people.rolesRights.details.path, {
          id: "new",
        });
        break;
      }
      case EIntentAction.viewOpeningHours: {
        redirectUrl = routeDefinitions.organizations.openingHours.templates.path;
        break;
      }
      case EIntentAction.listOrganizationUnitSets:
        redirectUrl = routeDefinitions.organizations.organizationsSets.overview.path;
        break;
      case EIntentAction.viewOrganizationUnitSet:
        redirectUrl = generatePath(routeDefinitions.organizations.organizationsSets.details.path, {
          id: payload?.id,
        });
        return;
      case EIntentAction.createOrganizationUnitSet:
        redirectUrl = generatePath(routeDefinitions.organizations.organizationsSets.details.path, {
          id: "new",
        });
        return;
      case EIntentAction.viewOrganizationUnit:
        redirectUrl = generatePath(routeDefinitions.organizations.organizations.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listOrganizationUnit:
        redirectUrl = routeDefinitions.organizations.organizations.overview.path;
        break;
      case EIntentAction.listOrganizationCustomFields:
        redirectUrl = routeDefinitions.controlRoom.customFields.organizationUnit.path;
        break;
      case EIntentAction.createOrganizationCustomField:
        redirectUrl = routeDefinitions.controlRoom.customFields.organizationUnit.new.path;
        break;
      case EIntentAction.viewOrganizationCustomField:
        redirectUrl = generatePath(
          routeDefinitions.controlRoom.customFields.organizationUnit.details.path,
          {
            id: payload?.id,
          },
        );
        break;
      case EIntentAction.listVisibilityGroups:
        redirectUrl = routeDefinitions.organizations.visibilityGroups.overview.path;
        break;
      case EIntentAction.viewVisibilityGroup:
        redirectUrl = generatePath(routeDefinitions.organizations.visibilityGroups.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createVisibilityGroup:
        redirectUrl = generatePath(routeDefinitions.organizations.visibilityGroups.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.searchVisibilityGroups: {
        const searchParams = new URLSearchParams();
        searchParams.append("name", payload?.query ?? "");
        redirectUrl = `${routeDefinitions.organizations.visibilityGroups.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.listInvoices:
        redirectUrl = routeDefinitions.financials.invoices.overview.path;
        break;
      case EIntentAction.viewInvoice:
        redirectUrl = generatePath(routeDefinitions.financials.invoices.details.view.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createInvoice:
        redirectUrl = `${routeDefinitions.financials.invoices.details.new.path}?view=general-data-info`;
        break;
      case EIntentAction.listCurrencies:
        redirectUrl = routeDefinitions.financials.currencies.overview.path;
        break;
      case EIntentAction.viewCurrency:
        redirectUrl = generatePath(routeDefinitions.financials.currencies.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createPriceList:
        redirectUrl = routeDefinitions.financials.priceLists.create.path;
        break;
      case EIntentAction.viewPriceList:
        redirectUrl = generatePath(routeDefinitions.financials.priceLists.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listPriceLists:
        redirectUrl = routeDefinitions.financials.priceLists.overview.path;
        break;
      case EIntentAction.listPriceListsByName:
        redirectUrl = `${routeDefinitions.financials.priceLists.overview.path}?query=${payload?.query}`;
        break;
      case EIntentAction.listPaymentMethods:
        redirectUrl = routeDefinitions.financials.payment.paymentMethods.overview.path;
        break;
      case EIntentAction.listPaymentTypesByName:
        redirectUrl = `${routeDefinitions.financials.payment.paymentMethods.overview.path}?query=${payload?.query}`;
        break;
      case EIntentAction.listTaxRates:
        redirectUrl = routeDefinitions.financials.taxManagement.overview.path;
        break;
      case EIntentAction.viewIncomeAndExpense:
      case EIntentAction.createIncomeAndExpense: {
        const searchParams = new URLSearchParams();
        searchParams.append("editId", payload?.id ?? "new");
        redirectUrl = `${routeDefinitions.financials.incomeAndExpenses.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.listIncomeAndExpense: {
        const searchParams = new URLSearchParams();
        searchParams.append("ouId", payload?.organizationUnitID ?? "");
        redirectUrl = `${routeDefinitions.financials.incomeAndExpenses.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.createGiftcard: {
        redirectUrl = generatePath(routeDefinitions.financials.giftcards.details.path, {
          id: "new",
        });
        break;
      }
      case EIntentAction.viewGiftcard: {
        redirectUrl = generatePath(routeDefinitions.financials.giftcards.details.path, {
          id: payload?.id,
        });
        break;
      }
      case EIntentAction.listGiftcards: {
        redirectUrl = routeDefinitions.financials.giftcards.overview.path;
        break;
      }
      case EIntentAction.searchGiftcard: {
        const searchParams = new URLSearchParams();
        searchParams.append("name", payload?.name ?? "");
        searchParams.append("handler", payload?.handler ?? "");
        searchParams.append("usageType", payload?.usageType ?? "");
        redirectUrl = `${routeDefinitions.financials.giftcards.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.listProductBundles:
        redirectUrl = routeDefinitions.pim.productBundles.overview.path;
        break;
      case EIntentAction.viewProductBundle:
        redirectUrl = generatePath(routeDefinitions.pim.productBundles.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createProductBundle:
        redirectUrl = generatePath(routeDefinitions.pim.productBundles.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.listOrderOrchestrationSheets:
        redirectUrl = routeDefinitions.orders.orchestration.overview.path;
        break;
      case EIntentAction.viewOrderOrchestrationSheet:
        redirectUrl = generatePath(routeDefinitions.orders.orchestration.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.viewOrder:
        redirectUrl = generatePath(routeDefinitions.orders.orders.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listOrder:
        redirectUrl = routeDefinitions.orders.orders.overview.path;
        break;
      case EIntentAction.listOrderFields:
        redirectUrl = routeDefinitions.controlRoom.customFields.order.path;
        break;
      case EIntentAction.createDiscount:
        redirectUrl = generatePath(routeDefinitions.promotions.promotions.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.viewDiscount:
        redirectUrl = generatePath(routeDefinitions.promotions.promotions.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listDiscount:
        redirectUrl = routeDefinitions.promotions.promotions.overview.path;
        break;
      case EIntentAction.searchDiscount: {
        const searchParams = new URLSearchParams();
        searchParams.append("query", payload?.query ?? "");
        redirectUrl = `${routeDefinitions.promotions.promotions.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.createDiscountTemplate:
        redirectUrl = generatePath(routeDefinitions.promotions.templates.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.viewDiscountTemplate:
        redirectUrl = generatePath(routeDefinitions.promotions.templates.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listDiscountTemplates:
        redirectUrl = routeDefinitions.promotions.templates.overview.path;
        break;
      case EIntentAction.searchDiscountTemplate: {
        const searchParams = new URLSearchParams();
        searchParams.append("query", payload?.query ?? "");
        redirectUrl = `${routeDefinitions.promotions.templates.overview.path}?${searchParams}`;
        break;
      }
      case EIntentAction.listDiscountCampaigns:
        redirectUrl = routeDefinitions.promotions.campaigns.overview.path;
        break;
      case EIntentAction.listReturnToSupplierRequests:
        redirectUrl = routeDefinitions.stockManagement.rts.path;
        break;
      case EIntentAction.viewReturnToSupplierRequest:
        redirectUrl = generatePath(routeDefinitions.stockManagement.rts.recalls.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.createReturnToSupplierRequest:
        redirectUrl = generatePath(routeDefinitions.stockManagement.rts.recalls.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.viewSupplierRelation:
        redirectUrl = generatePath(routeDefinitions.stockManagement.allocation.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listSupplierRelations:
        redirectUrl = routeDefinitions.stockManagement.allocation.overview.path;
        break;
      case EIntentAction.createAssortment:
        redirectUrl = generatePath(routeDefinitions.stockManagement.assortments.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.viewAssortment:
        redirectUrl = generatePath(routeDefinitions.stockManagement.assortments.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listAssortments:
        redirectUrl = routeDefinitions.stockManagement.assortments.overview.path;
        break;
      case EIntentAction.createFullStockCount:
        redirectUrl = generatePath(routeDefinitions.tasks.fullStockCount.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.viewFullStockCount:
        redirectUrl = generatePath(routeDefinitions.tasks.fullStockCount.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listFullStockCounts:
        redirectUrl = routeDefinitions.tasks.fullStockCount.overview.path;
        break;
      case EIntentAction.viewOrderFulfillment:
        redirectUrl = generatePath(
          routeDefinitions.stockManagement.orderFulfillments.details.path,
          {
            id: payload?.id,
          },
        );
        break;
      case EIntentAction.listOrderFulfillments:
        redirectUrl = routeDefinitions.stockManagement.orderFulfillments.overview.path;
        break;
      case EIntentAction.createPurchaseOrder:
        redirectUrl = generatePath(routeDefinitions.stockManagement.purchaseOrders.details.path, {
          id: "new",
        });
        break;
      case EIntentAction.viewPurchaseOrder:
        redirectUrl = generatePath(routeDefinitions.stockManagement.purchaseOrders.details.path, {
          id: payload?.id,
        });
        break;
      case EIntentAction.listPurchaseOrders:
        redirectUrl = routeDefinitions.stockManagement.purchaseOrders.overview.path;
        break;
      case EIntentAction.listReplenishments:
        redirectUrl =
          routeDefinitions.stockManagement.replenishments.replenishmentProducts.overview.path;
        break;
      // This is the generic 'list tasks' intent
      // but a duplicate of the ship from store intents
      //
      case EIntentAction.listUserTasks:
        redirectUrl = routeDefinitions.tasks.shipFromStore.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listShipFromStore:
        redirectUrl = routeDefinitions.tasks.shipFromStore.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;

      case EIntentAction.viewShipFromStore:
        // redirect to loader which checks the task type and in turn redirects to the correct detail page
        if (payload?.compositeId) {
          redirectUrl = generatePath(
            routeDefinitions.tasks.shipFromStore.taskDetailsRedirecter.compositeTask.path,
            {
              id: payload?.id,
              compositeId: payload?.compositeId,
            },
          );
        } else {
          redirectUrl = generatePath(
            routeDefinitions.tasks.shipFromStore.taskDetailsRedirecter.simpleTask.path,
            {
              id: payload?.id,
            },
          );
        }
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listIncomingShipments:
        redirectUrl = routeDefinitions.tasks.incomingShipment.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.viewIncomingShipment:
        redirectUrl = generatePath(routeDefinitions.tasks.incomingShipment.details.path, {
          id: payload?.id,
        });
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listReservationCleanup:
        redirectUrl = routeDefinitions.tasks.reservationCleanup.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.viewReservationCleanup:
        redirectUrl = generatePath(routeDefinitions.tasks.reservationCleanup.details.path, {
          id: payload?.id,
        });
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listReservations:
        redirectUrl = routeDefinitions.tasks.reservation.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.viewReservation:
        redirectUrl = generatePath(routeDefinitions.tasks.reservation.details.path, {
          id: payload?.id,
        });
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listPrintTasks:
        redirectUrl = routeDefinitions.tasks.print.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listCycleCountZones:
        redirectUrl = routeDefinitions.tasks.zonesAndZoneGroups.zones.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listCycleCountZoneGroups:
        redirectUrl = routeDefinitions.tasks.zonesAndZoneGroups.zoneGroups.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.createCycleCountZoneGroup:
        redirectUrl = generatePath(routeDefinitions.tasks.zonesAndZoneGroups.zoneGroups.edit.path, {
          id: "new",
        });
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.viewCycleCountZoneGroup:
        redirectUrl = generatePath(routeDefinitions.tasks.zonesAndZoneGroups.zoneGroups.edit.path, {
          id: payload?.id,
        });
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listZonedCycleCounts:
        redirectUrl = routeDefinitions.tasks.zonedCycleCounts.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.createZonedCycleCount:
        redirectUrl = routeDefinitions.tasks.zonedCycleCounts.create.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.listZonedCycleCountSchedules:
        redirectUrl = routeDefinitions.tasks.zonedCycleCountsSchedules.overview.path;
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.createZonedCycleCountSchedule:
        redirectUrl = generatePath(routeDefinitions.tasks.zonedCycleCountsSchedules.details.path, {
          id: "new",
        });
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;
      case EIntentAction.viewZonedCycleCountSchedule:
        redirectUrl = generatePath(routeDefinitions.tasks.zonedCycleCountsSchedules.details.path, {
          id: payload?.id,
        });
        requestedOrganizationUnitId = payload?.organizationUnitID;
        break;

      default:
        console.log(`[INTENT HANDLER]: Found unhandled intent action: ${action}.`);
        redirectUrl = "/";
    }

    if (token) {
      console.log(`[INTENT HANDLER]: Found user token. Navigating to ${redirectUrl}.`);
      storage.setItem("evaUserToken", token);
      if (requestedOrganizationUnitId) {
        setServiceSetting("requestedOrganizationUnitID", requestedOrganizationUnitId);
      }
      return redirect(redirectUrl, 302);
    } else {
      const queryString = new URLSearchParams();
      queryString.set("from", redirectUrl);

      console.log(
        `[INTENT HANDLER] Found no user token token; redirecting to /login?${queryString.toString()}`,
      );
      return redirect(`/login?${queryString.toString()}`, 302);
    }
  } catch (error) {
    console.error("[INTENT HANDLER]: setting token failed. Now redirecting to login page.", error);
    return redirect("/login", 302);
  }
};
