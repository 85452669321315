import { Navigate, redirect, RouteObject } from "react-router-dom";

import { NavigateWithParams } from "components/routing";
import { EModuleCode } from "features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "routes/lazy-with-retry";
import ModuleChapterRedirecter from "routes/module-chapter-redirecter";
import controlRoom from "routes/route-definitions/control-room";
import people from "routes/route-definitions/people";

const userRequirementsRedirects: RouteObject = {
  path: "/people/user-requirements/*",
  loader: ({ request }) => {
    const url = new URL(request.url);
    // replace user-requirements with user-requirement-sets
    url.pathname = url.pathname.replace("user-requirements", "user-requirement-sets");
    return redirect(url.toString());
  },
};

// Parent is root /
export const peopleRoutes: RouteObject[] = [
  {
    path: people.module.path,
    lazy: lazyWithRetry(() => import("./people")),
    children: [
      { index: true, element: <ModuleChapterRedirecter moduleCode={EModuleCode.PEOPLE} /> },
      userRequirementsRedirects,
      {
        path: people.userRequirementSets.overview.path,
        lazy: lazyWithRetry(() => import("./people.user-requirement-sets")),
      },
      {
        path: people.userRequirementSets.create.path,
        lazy: lazyWithRetry(() => import("./people.user-requirement-sets.new")),
      },
      {
        path: people.userRequirementSets.duplicate.path,
        lazy: lazyWithRetry(() => import("./people.user-requirement-sets.$id.duplicate")),
      },
      {
        path: people.userRequirementSets.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.user-requirement-sets.$id"),
            import("./people.user-requirement-sets.$id.edit"),
            import("./people.user-requirement-sets.$id.$requirementID.edit"),
            import("./people.user-requirement-sets.$id.$requirementID.delete"),
            import("./people.user-requirement-sets.$id.create-custom-requirement"),
          ]);

          return page;
        }),
        children: [
          {
            path: people.userRequirementSets.details.edit.path,
            lazy: lazyWithRetry(() => import("./people.user-requirement-sets.$id.edit")),
          },
          {
            path: people.userRequirementSets.details.requirement.createCustomRequirement.path,
            lazy: lazyWithRetry(
              () => import("./people.user-requirement-sets.$id.create-custom-requirement"),
            ),
          },
          {
            path: people.userRequirementSets.details.requirement.edit.path,
            lazy: lazyWithRetry(
              () => import("./people.user-requirement-sets.$id.$requirementID.edit"),
            ),
          },
          {
            path: people.userRequirementSets.details.requirement.delete.path,
            lazy: lazyWithRetry(
              () => import("./people.user-requirement-sets.$id.$requirementID.delete"),
            ),
          },
        ],
      },

      {
        path: "custom-user-fields",
        children: [
          {
            index: true,
            element: <Navigate to={controlRoom.customFields.user.path} />,
          },
          {
            path: "new",
            element: <Navigate to={controlRoom.customFields.user.new.path} />,
          },
          {
            path: ":id",
            element: <NavigateWithParams path={controlRoom.customFields.user.details.path} />,
          },
          {
            path: ":id/edit-general-info",
            element: (
              <NavigateWithParams
                path={controlRoom.customFields.user.details.editGeneralInformation.path}
              />
            ),
          },
        ],
      },

      {
        path: people.employees.overview.path,
        lazy: lazyWithRetry(() => import("./people.employees")),
      },
      {
        path: people.employees.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.employees.$id"),
            import("./people.employees.$id.disable-two-factor-authentication"),
            import("./people.employees.$id.recover-account"),
            import("./people.employees.$id.sso-only"),
            import("./people.employees.$id.temporary-token"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.employees.details.disableTwoFactorAuthentication.path,
            lazy: lazyWithRetry(
              () => import("./people.employees.$id.disable-two-factor-authentication"),
            ),
          },
          {
            path: people.employees.details.recoverAccount.path,
            lazy: lazyWithRetry(() => import("./people.employees.$id.recover-account")),
          },
          {
            path: people.employees.details.temporaryToken.path,
            lazy: lazyWithRetry(() => import("./people.employees.$id.temporary-token")),
          },
          {
            path: people.employees.details.ssoOnly.path,
            lazy: lazyWithRetry(() => import("./people.employees.$id.sso-only")),
          },
        ],
      },
      {
        path: people.rolesRights.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.roles-rights"),
            import("./people.roles-rights.new"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.rolesRights.create.path,
            lazy: lazyWithRetry(() => import("./people.roles-rights.new")),
          },
        ],
      },
      {
        path: people.rolesRights.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.roles-rights.$id"),
            import("./people.roles-rights.$id.edit-general-details"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.rolesRights.details.editGeneralDetails.path,
            lazy: lazyWithRetry(() => import("./people.roles-rights.$id.edit-general-details")),
          },
          {
            path: people.rolesRights.details.removeWorkspace.path,
            lazy: lazyWithRetry(
              () => import("./people.roles-rights.$id.remove-workspace.$workspaceID"),
            ),
          },
        ],
      },
      {
        path: people.roleSets.overview.path,
        lazy: lazyWithRetry(() => import("./people.role-sets")),
      },
      {
        path: people.roleSets.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.role-sets.$id"),
            import("./people.role-sets.$id.attach-roles"),
            import("./people.role-sets.$id.attach-ou-set"),
            import("./people.role-sets.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.roleSets.details.attachRolesToRoleSet.path,
            lazy: lazyWithRetry(() => import("./people.role-sets.$id.attach-roles")),
          },
          {
            path: people.roleSets.details.attachOUSetToRoleSet.path,
            lazy: lazyWithRetry(() => import("./people.role-sets.$id.attach-ou-set")),
          },
          {
            path: people.roleSets.details.delete.path,
            lazy: lazyWithRetry(() => import("./people.role-sets.$id.delete")),
          },
        ],
      },

      {
        path: people.subscriptions.overview.path,
        lazy: lazyWithRetry(() => import("./people.subscriptions")),
      },
      {
        path: people.subscriptions.create.path,
        lazy: lazyWithRetry(() => import("./people.subscriptions.new")),
      },
      {
        path: people.subscriptions.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.subscriptions.$id"),
            import("./people.subscriptions.$id.edit-general-info"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.subscriptions.details.editGeneralInfo.path,
            lazy: lazyWithRetry(() => import("./people.subscriptions.$id.edit-general-info")),
          },
        ],
      },
      {
        path: people.inquiries.overview.path,
        lazy: lazyWithRetry(() => import("./people.inquiries")),
      },
      {
        path: people.inquiries.details.path,
        lazy: lazyWithRetry(() => import("./people.inquiries.$id")),
      },
      {
        path: people.consumers.overview.path,
        lazy: lazyWithRetry(() => import("./people.consumers")),
      },
      {
        path: people.consumers.create.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.consumers.new"),
            import("./people.consumers.new.add-phone-number"),
            import("./people.consumers.new.edit-phone-number.$phoneNumberId"),
          ]);

          return page;
        }),

        children: [
          {
            path: people.consumers.create.addPhoneNumber.path,
            lazy: lazyWithRetry(() => import("./people.consumers.new.add-phone-number")),
          },
          {
            path: people.consumers.create.editPhoneNumber.path,
            lazy: lazyWithRetry(
              () => import("./people.consumers.new.edit-phone-number.$phoneNumberId"),
            ),
          },
        ],
      },
      {
        path: people.consumers.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.consumers.$id"),
            import("./people.consumers.$id.general-info"),
            import("./people.consumers.$id.custom-fields"),
            import("./people.consumers.$id.company"),
            import("./people.consumers.$id.recent-orders"),
            import("./people.consumers.$id.interaction-logs"),
            import("./people.consumers.$id.inquiries"),
            import("./people.consumers.$id.cases"),
            import("./people.consumers.$id.wishlists"),
            import("./people.consumers.$id.user-cards"),
            import("./people.consumers.$id.bought-products"),
            import("./people.consumers.$id.personalized-promotions"),
          ]);

          return page;
        }),
        children: [
          {
            index: true,
            element: (
              <NavigateWithParams path={people.consumers.details.generalInfo.path} replace />
            ),
          },
          {
            path: people.consumers.details.generalInfo.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.general-info"),
                import("./people.consumers.$id.general-info.edit"),
                import("./people.consumers.$id.general-info.add-address"),
                import("./people.consumers.$id.general-info.edit-address"),
                import("./people.consumers.$id.general-info.add-phone-number"),
                import("./people.consumers.$id.general-info.edit-phone-number.$phone-number-id"),
                import("./people.consumers.$id.general-info.edit-subscriptions"),
                import(
                  "./people.consumers.$id.general-info.deposit-loyalty-points.$loyaltyProgramID"
                ),
                import(
                  "./people.consumers.$id.general-info.block-user-subscription.$subscriptionID"
                ),
                import(
                  "./people.consumers.$id.general-info.unblock-user-subscription.$subscriptionID"
                ),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.generalInfo.edit.path,
                lazy: lazyWithRetry(() => import("./people.consumers.$id.general-info.edit")),
              },
              {
                path: people.consumers.details.generalInfo.addAddress.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.general-info.add-address"),
                ),
              },
              {
                path: people.consumers.details.generalInfo.editAddress.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.general-info.edit-address"),
                ),
              },
              {
                path: people.consumers.details.generalInfo.addPhoneNumber.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.general-info.add-phone-number"),
                ),
              },
              {
                path: people.consumers.details.generalInfo.editPhoneNumber.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./people.consumers.$id.general-info.edit-phone-number.$phone-number-id"
                    ),
                ),
              },
              {
                path: people.consumers.details.generalInfo.editSubscriptions.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.general-info.edit-subscriptions"),
                ),
              },
              {
                path: people.consumers.details.generalInfo.depositLoyaltyPoints.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./people.consumers.$id.general-info.deposit-loyalty-points.$loyaltyProgramID"
                    ),
                ),
              },
              {
                path: people.consumers.details.generalInfo.blockUserSubscription.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./people.consumers.$id.general-info.block-user-subscription.$subscriptionID"
                    ),
                ),
              },
              {
                path: people.consumers.details.generalInfo.unblockUserSubscription.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./people.consumers.$id.general-info.unblock-user-subscription.$subscriptionID"
                    ),
                ),
              },
            ],
          },
          {
            path: people.consumers.details.customFields.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.custom-fields"),
                import("./people.consumers.$id.custom-fields.new"),
                import("./people.consumers.$id.custom-fields.$customFieldId"),
                import("./people.consumers.$id.custom-fields.$customFieldId.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.customFields.addCustomField.path,
                lazy: lazyWithRetry(() => import("./people.consumers.$id.custom-fields.new")),
              },
              {
                path: people.consumers.details.customFields.editCustomField.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.custom-fields.$customFieldId"),
                ),
              },
              {
                path: people.consumers.details.customFields.deleteCustomField.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.custom-fields.$customFieldId.delete"),
                ),
              },
            ],
          },
          {
            path: people.consumers.details.company.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.company"),
                import("./people.consumers.$id.company.create"),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.company.create.path,
                lazy: lazyWithRetry(() => import("./people.consumers.$id.company.create")),
              },
            ],
          },
          {
            path: people.consumers.details.recentOrders.path,
            lazy: lazyWithRetry(() => import("./people.consumers.$id.recent-orders")),
          },
          {
            path: people.consumers.details.interactionLogs.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.interaction-logs"),
                import("./people.consumers.$id.interaction-logs.add"),
                import("./people.consumers.$id.interaction-logs.edit.$interactionLogId"),
                import("./people.consumers.$id.interaction-logs.delete.$interactionLogId"),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.interactionLogs.addInteractionLog.path,
                lazy: lazyWithRetry(() => import("./people.consumers.$id.interaction-logs.add")),
              },
              {
                path: people.consumers.details.interactionLogs.editInteractionLog.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.interaction-logs.edit.$interactionLogId"),
                ),
              },
              {
                path: people.consumers.details.interactionLogs.deleteInteractionLog.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.interaction-logs.delete.$interactionLogId"),
                ),
              },
            ],
          },
          {
            path: people.consumers.details.inquiries.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.inquiries"),
                import("./people.consumers.$id.inquiries.$inquiryID"),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.inquiries.details.path,
                lazy: lazyWithRetry(() => import("./people.consumers.$id.inquiries.$inquiryID")),
              },
            ],
          },
          {
            path: people.consumers.details.cases.path,
            lazy: lazyWithRetry(() => import("./people.consumers.$id.cases")),
          },
          {
            path: people.consumers.details.wishlists.path,
            lazy: lazyWithRetry(() => import("./people.consumers.$id.wishlists")),
          },
          {
            path: people.consumers.details.userCards.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.user-cards"),
                import("./people.consumers.$id.user-cards.add.$userCardId"),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.userCards.add.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.user-cards.add.$userCardId"),
                ),
              },
            ],
          },
          {
            path: people.consumers.details.boughtProducts.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.bought-products"),
                import("./people.consumers.$id.bought-products.add"),
                import("./people.consumers.$id.bought-products.delete.$bougtProductID"),
                import("./people.consumers.$id.bought-products.warranty.$boughtProductID"),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.boughtProducts.warranty.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.bought-products.warranty.$boughtProductID"),
                ),
              },
              {
                path: people.consumers.details.boughtProducts.add.path,
                lazy: lazyWithRetry(() => import("./people.consumers.$id.bought-products.add")),
              },
              {
                path: people.consumers.details.boughtProducts.delete.path,
                lazy: lazyWithRetry(
                  () => import("./people.consumers.$id.bought-products.delete.$bougtProductID"),
                ),
              },
            ],
          },
          {
            path: people.consumers.details.personalizedPromotions.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.consumers.$id.personalized-promotions"),
                import("./people.consumers.$id.personalized-promotions.$personalizedPromotionID"),
              ]);
              return page;
            }),
            children: [
              {
                path: people.consumers.details.personalizedPromotions.details.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./people.consumers.$id.personalized-promotions.$personalizedPromotionID"
                    ),
                ),
              },
            ],
          },
        ],
      },

      {
        path: people.cases.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.cases"),
            import("./people.cases.files"),
            import("./people.cases.files.new"),
            import("./people.cases.topics"),
            import("./people.cases.topics.new"),
            import("./people.cases.topics.$id.edit-general-info"),
            import("./people.cases.topics.$id.delete"),
            import("./people.cases.statuses"),
            import("./people.cases.statuses.new"),
            import("./people.cases.statuses.$id.edit-general-info"),
            import("./people.cases.statuses.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.cases.files.overview.path,
            lazy: lazyWithRetry(() => import("./people.cases.files")),
          },
          {
            path: people.cases.topics.overview.path,
            lazy: lazyWithRetry(() => import("./people.cases.topics")),

            children: [
              {
                path: people.cases.topics.new.path,
                lazy: lazyWithRetry(() => import("./people.cases.topics.new")),
              },
              {
                path: people.cases.topics.edit.path,
                lazy: lazyWithRetry(() => import("./people.cases.topics.$id.edit-general-info")),
              },
              {
                path: people.cases.topics.delete.path,
                lazy: lazyWithRetry(() => import("./people.cases.topics.$id.delete")),
              },
            ],
          },
          {
            path: people.cases.statuses.overview.path,
            lazy: lazyWithRetry(() => import("./people.cases.statuses")),

            children: [
              {
                path: people.cases.statuses.new.path,
                lazy: lazyWithRetry(() => import("./people.cases.statuses.new")),
              },
              {
                path: people.cases.statuses.edit.path,
                lazy: lazyWithRetry(() => import("./people.cases.statuses.$id.edit-general-info")),
              },
              {
                path: people.cases.statuses.delete.path,
                lazy: lazyWithRetry(() => import("./people.cases.statuses.$id.delete")),
              },
            ],
          },
        ],
      },

      {
        path: people.cases.files.new.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.cases.files.new"),
            import("./people.cases.files.new.create-consumer"),
            import("./people.cases.files.new.search-consumer"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.cases.files.new.createConsumer.path,
            lazy: lazyWithRetry(() => import("./people.cases.files.new.create-consumer")),
          },
          {
            path: people.cases.files.new.searchConsumer.path,
            lazy: lazyWithRetry(() => import("./people.cases.files.new.search-consumer")),
          },
        ],
      },

      {
        path: people.cases.files.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./people.cases.files.$id"),
            import("./people.cases.files.$id.general-information"),
            import("./people.cases.files.$id.repairs"),
            import("./people.cases.files.$id.interaction-logs"),
          ]);
          return page;
        }),
        children: [
          {
            path: people.cases.files.details.generalInformation.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.cases.files.$id.general-information"),
                import("./people.cases.files.$id.general-information.add-media"),
                import("./people.cases.files.$id.general-information.delete-media.$blobId"),
                import("./people.cases.files.$id.general-information.edit-consumer"),
                import("./people.cases.files.$id.general-information.add-product"),
                import("./people.cases.files.$id.general-information.add-order"),
                import("./people.cases.files.$id.general-information.add-product-from-order"),
                import("./people.cases.files.$id.general-information.add-bought-product"),
                import("./people.cases.files.$id.general-information.set-status"),
                import("./people.cases.files.$id.general-information.set-assignee"),
                import("./people.cases.files.$id.general-information.set-summary"),
                import("./people.cases.files.$id.general-information.delete-assignee"),
              ]);
              return page;
            }),

            children: [
              {
                path: people.cases.files.details.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.edit"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.setStatus.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.set-status"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.setAssignee.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.set-assignee"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.setSummary.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.set-summary"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.deleteAssignee.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.delete-assignee"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.editConsumer.path,
                lazy: lazyWithRetry(async () => {
                  const [page] = await Promise.all([
                    import("./people.cases.files.$id.general-information.edit-consumer"),
                    import(
                      "./people.cases.files.$id.general-information.edit-consumer.select-address.$addressType"
                    ),
                    import(
                      "./people.cases.files.$id.general-information.edit-consumer.add-address.$addressType"
                    ),
                    import(
                      "./people.cases.files.$id.general-information.edit-consumer.edit-address.$addressType.$addressID"
                    ),
                  ]);
                  return page;
                }),
                children: [
                  {
                    path: people.cases.files.details.generalInformation.editConsumer.selectAddress
                      .path,
                    lazy: lazyWithRetry(
                      () =>
                        import(
                          "./people.cases.files.$id.general-information.edit-consumer.select-address.$addressType"
                        ),
                    ),
                  },
                  {
                    path: people.cases.files.details.generalInformation.editConsumer.addAddress
                      .path,
                    lazy: lazyWithRetry(
                      () =>
                        import(
                          "./people.cases.files.$id.general-information.edit-consumer.add-address.$addressType"
                        ),
                    ),
                  },
                  {
                    path: people.cases.files.details.generalInformation.editConsumer.editAddress
                      .path,
                    lazy: lazyWithRetry(
                      () =>
                        import(
                          "./people.cases.files.$id.general-information.edit-consumer.edit-address.$addressType.$addressID"
                        ),
                    ),
                  },
                ],
              },
              {
                path: people.cases.files.details.generalInformation.addMedia.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.add-media"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.deleteMedia.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.delete-media.$blobId"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.customFields.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./people.cases.files.$id.general-information.custom-fields.$customFieldId.edit"
                    ),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./people.cases.files.$id.general-information.custom-fields.$customFieldId.delete"
                    ),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.addProduct.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.add-product"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.addProductFromOrder.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./people.cases.files.$id.general-information.add-product-from-order"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.addBoughtProduct.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.add-bought-product"),
                ),
              },
              {
                path: people.cases.files.details.generalInformation.addOrder.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.general-information.add-order"),
                ),
              },
            ],
          },
          {
            path: people.cases.files.details.repairs.path,
            lazy: lazyWithRetry(() => import("./people.cases.files.$id.repairs")),
          },

          {
            path: people.cases.files.details.interactionLogs.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./people.cases.files.$id.interaction-logs"),
                import("./people.cases.files.$id.interaction-logs.$interactionLogId.delete"),
                import("./people.cases.files.$id.interaction-logs.new"),
                import("./people.cases.files.$id.interaction-logs.$interactionLogId.edit"),
              ]);
              return page;
            }),

            children: [
              {
                path: people.cases.files.details.interactionLogs.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./people.cases.files.$id.interaction-logs.$interactionLogId.delete"),
                ),
              },
              {
                path: people.cases.files.details.interactionLogs.create.path,
                lazy: lazyWithRetry(() => import("./people.cases.files.$id.interaction-logs.new")),
              },
              {
                path: people.cases.files.details.interactionLogs.edit.path,
                lazy: lazyWithRetry(
                  () => import("./people.cases.files.$id.interaction-logs.$interactionLogId.edit"),
                ),
              },
            ],
          },
        ],
      },

      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
    ],
  },
];
