import { redirect, RouteObject } from "react-router-dom";

import { NavigateWithParams } from "components/routing";
import { EModuleCode } from "features/chapter-finder/chapter-finder.types";
import { adminRedirectAction } from "routes/admin-redirect/admin-redirect.action";
import { adminRedirectLoader } from "routes/admin-redirect/admin-redirect.loader";
import AdminRedirectPage from "routes/admin-redirect/admin-redirect-page";
import { evaIntentHandlerLoader } from "routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "routes/lazy-with-retry";
import ModuleChapterRedirecter from "routes/module-chapter-redirecter";
import routeDefinitions from "routes/route-definitions";
import controlRoom from "routes/route-definitions/control-room";
import { intlAccessor } from "util/intl-accessor";
import { queryClient } from "util/query-client";

import { adminSuiteConfig } from "../../../admin-suite.config";

const { orders } = routeDefinitions;

const getOrderDetailsV2Routes = () => {
  let routes: RouteObject[] = [];

  if (adminSuiteConfig.orderDetailsV2) {
    routes = [
      {
        path: orders.orders.detailsNew.path,
        lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id._index")),
        children: [
          {
            index: true,
            element: <NavigateWithParams path={orders.orders.detailsNew.orderLines.path} replace />,
          },
          {
            path: orders.orders.detailsNew.orderLines.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.order-lines")),
          },
          {
            path: orders.orders.detailsNew.payments.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.payments")),
          },
          {
            path: orders.orders.detailsNew.shipments.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.shipments")),
          },
          {
            path: orders.orders.detailsNew.documents.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.documents")),
          },
          {
            path: orders.orders.detailsNew.orderHistory.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.order-history")),
          },
          {
            path: orders.orders.detailsNew.relatedOrders.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.related-orders")),
          },
          {
            path: orders.orders.detailsNew.bookings.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.bookings")),
          },
          {
            path: orders.orders.detailsNew.exports.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.exports")),
          },
          {
            path: orders.orders.detailsNew.interactionLogs.path,
            lazy: lazyWithRetry(() => import("./orders-new/orders.orders.$id.interaction-logs")),
          },
          {
            path: orders.orders.detailsNew.orderlineDetails.path,
            lazy: lazyWithRetry(
              () => import("./orders-new/orders.orders.$id.orderline.$orderlineid"),
            ),
          },
          {
            path: orders.orders.detailsNew.editOrder.path,
            lazy: lazyWithRetry(
              () => import("./orders-new/orders.orders.$id.$tab.($orderlineid).edit-order"),
            ),
          },
          {
            path: orders.orders.detailsNew.transferOrder.path,
            lazy: lazyWithRetry(
              () => import("./orders-new/orders.orders.$id.$tab.($orderlineid).transfer-order"),
            ),
          },
          {
            path: orders.orders.detailsNew.duplicateToPOS.path,
            lazy: lazyWithRetry(
              () => import("./orders-new/orders.orders.$id.$tab.($orderlineid).duplicate-to-pos"),
            ),
          },
          {
            path: orders.orders.detailsNew.editRemark.path,
            lazy: lazyWithRetry(
              () => import("./orders-new/orders.orders.$id.$tab.($orderlineid).edit-remark"),
            ),
          },
          {
            path: orders.orders.detailsNew.editCustomFields.path,
            lazy: lazyWithRetry(
              () => import("./orders-new/orders.orders.$id.$tab.($orderlineid).edit-custom-fields"),
            ),
          },
          {
            path: orders.orders.detailsNew.editCustomer.path,
            lazy: lazyWithRetry(
              () => import("./orders-new/orders.orders.$id.$tab.($orderlineid).edit-customer"),
            ),
          },
          {
            path: orders.orders.detailsNew.editCustomerDetachCustomer.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./orders-new/orders.orders.$id.$tab.($orderlineid).edit-customer.detach-customer"
                ),
            ),
          },
          {
            path: orders.orders.detailsNew.editCustomerAddressActions.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./orders-new/orders.orders.$id.$tab.($orderlineid).edit-customer.$addresstype.$addressaction"
                ),
            ),
          },
        ],
      },
    ];
  }

  return routes;
};

// Parent is root /
export const ordersRoutes: RouteObject[] = [
  {
    path: orders.module.path,
    lazy: lazyWithRetry(() => import("./orders")),
    children: [
      { index: true, element: <ModuleChapterRedirecter moduleCode={EModuleCode.ORDERS} /> },
      {
        path: orders.orders.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./orders.orders._index"),
            import("./orders.orders.$id"),
            import("./orders.orders.$id.verify"),
            import("./orders.orders.$id.$orderlineid"),
          ]);
          return page;
        }),
      },
      {
        path: orders.orders.details.path,
        lazy: lazyWithRetry(() => import("./orders.orders.$id")),
        children: [
          {
            path: orders.orders.details.verify.path,
            lazy: lazyWithRetry(() => import("./orders.orders.$id.verify")),
          },
        ],
      },
      ...getOrderDetailsV2Routes(),
      {
        path: orders.orders.orderlineDetails.path,
        lazy: lazyWithRetry(() => import("./orders.orders.$id.$orderlineid")),
      },
      {
        path: orders.orchestration.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./orders.orchestration._index")),
          },
          {
            path: orders.orchestration.previewOrderFulfillment.path,
            lazy: lazyWithRetry(() => import("./orders.orchestration.preview-order-fulfillment")),
          },
          {
            path: orders.orchestration.details.path,
            lazy: lazyWithRetry(() => import("./orders.orchestration.$id")),
          },
        ],
      },
      {
        path: "/orders/return-reasons",
        loader: () => redirect(controlRoom.reasons.organizationReturn.path, 301),
      },

      {
        path: orders.exportLogs.overview.path,
        lazy: lazyWithRetry(() => import("./orders.export-logs")),
      },
      {
        path: orders.checkoutOptions.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./orders.checkout-options.__index"),
            import("./orders.checkout-options.$id"),
            import("./orders.checkout-options.$id.delete"),
            import("./orders.checkout-options.new"),
            import("./orders.checkout-options.category.$category-id"),
            import("./orders.checkout-options.category.$category-id.delete"),
            import("./orders.checkout-options.category.new"),
            import("./orders.checkout-options.reorder"),
          ]);
          return page;
        }),
        children: [
          {
            path: orders.checkoutOptions.reorder.path,
            lazy: lazyWithRetry(() => import("./orders.checkout-options.reorder")),
          },
          {
            path: orders.checkoutOptions.new.path,
            lazy: lazyWithRetry(() => import("./orders.checkout-options.new")),
          },
          {
            path: orders.checkoutOptions.edit.path,
            lazy: lazyWithRetry(() => import("./orders.checkout-options.$id")),
          },
          {
            path: orders.checkoutOptions.delete.path,
            lazy: lazyWithRetry(() => import("./orders.checkout-options.$id.delete")),
          },
          {
            path: orders.checkoutOptions.category.new.path,
            lazy: lazyWithRetry(() => import("./orders.checkout-options.category.new")),
          },
          {
            path: orders.checkoutOptions.category.edit.path,
            lazy: lazyWithRetry(() => import("./orders.checkout-options.category.$category-id")),
          },
          {
            path: orders.checkoutOptions.category.delete.path,
            lazy: lazyWithRetry(
              () => import("./orders.checkout-options.category.$category-id.delete"),
            ),
          },
        ],
      },

      ...(!adminSuiteConfig.waldoV2
        ? [
            {
              path: orders.customOrderStatuses.path,
              element: <AdminRedirectPage />,
              loader: adminRedirectLoader(
                queryClient,
                intlAccessor.formatMessage({
                  id: "module.orders.chapters.custom-order-statuses",
                  defaultMessage: "Custom order statuses",
                }),
                orders.customOrderStatuses.path,
                orders.customOrderStatuses.oldAdminPath,
              ),
              action: adminRedirectAction(orders.customOrderStatuses.oldAdminPath),
            },
            {
              path: orders.customOrderTypes.path,
              element: <AdminRedirectPage />,
              loader: adminRedirectLoader(
                queryClient,
                intlAccessor.formatMessage({
                  id: "module.orders.chapters.custom-order-types",
                  defaultMessage: "Custom order types",
                }),
                orders.customOrderTypes.path,
                orders.customOrderTypes.oldAdminPath,
              ),
              action: adminRedirectAction(orders.customOrderTypes.oldAdminPath),
            },
          ]
        : [
            {
              path: orders.orderCustomStatuses.overview.path,
              lazy: lazyWithRetry(async () => {
                const [page] = await Promise.all([
                  import("./orders.order-custom-statuses._index"),
                  import("./orders.order-custom-statuses.new"),
                  import("./orders.order-custom-statuses.$id.edit"),
                  import("./orders.order-custom-statuses.$id.delete"),
                ]);
                return page;
              }),
              children: [
                {
                  path: orders.orderCustomStatuses.overview.new.path,
                  lazy: lazyWithRetry(() => import("./orders.order-custom-statuses.new")),
                },
                {
                  path: orders.orderCustomStatuses.overview.edit.path,
                  lazy: lazyWithRetry(() => import("./orders.order-custom-statuses.$id.edit")),
                },
                {
                  path: orders.orderCustomStatuses.overview.delete.path,
                  lazy: lazyWithRetry(() => import("./orders.order-custom-statuses.$id.delete")),
                },
              ],
            },
            {
              path: orders.orderCustomTypes.overview.path,
              lazy: lazyWithRetry(() => import("./orders.order-custom-types._index")),
            },
            {
              path: orders.orderCustomTypes.new.path,
              lazy: lazyWithRetry(() => import("./orders.order-custom-types.new")),
            },
            {
              path: orders.orderCustomTypes.details.path,
              lazy: lazyWithRetry(async () => {
                const [page] = await Promise.all([
                  import("./orders.order-custom-types.$id"),
                  import("./orders.order-custom-types.$id.edit"),
                ]);
                return page;
              }),
              children: [
                {
                  path: orders.orderCustomTypes.details.edit.path,
                  lazy: lazyWithRetry(() => import("./orders.order-custom-types.$id.edit")),
                },
              ],
            },
          ]),
      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
      {
        path: ":id",
        element: <NavigateWithParams path={orders.orders.details.path} />,
      },
      {
        path: ":id/:orderlineid",
        element: <NavigateWithParams path={orders.orders.orderlineDetails.path} />,
      },
    ],
  },
];
